import React from "react";
import "./titleimage.css";

import { map } from "../../../../assests";

const TitleImage = (props) => {
  return (
    <div id="title-container">
      {props.desktop && (
        <svg id="title-svg-container" className="section-image">
          <clipPath id="title-circle-clippath">
            <circle
              id="map-circle0"
              className="map-circle"
              cx="50%"
              cy="50%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle1"
              className="map-circle"
              cx="30%"
              cy="35%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle2"
              className="map-circle"
              cx="70%"
              cy="35%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle3"
              className="map-circle"
              cx="35%"
              cy="70%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle4"
              className="map-circle"
              cx="67%"
              cy="52%"
              r="0.1px"
              fill="#ffffff"
            />
          </clipPath>

          <image
            id="title-map"
            href={map}
            clipPath="url(#title-circle-clippath)"
          />

          <circle
            id="position-circle-blue"
            className="position-circle"
            cx="50%"
            cy="50%"
            r="10px"
            fill="rgba(25, 118, 210, 1)"
          />
          <circle
            id="position-circle-blue-shadow"
            className="position-shadow"
            cx="50%"
            cy="50%"
            r="0"
            fill="rgba(25, 118, 210, 0)"
          />

          <circle
            id="position-circle-purple"
            className="position-circle"
            cx="30%"
            cy="35%"
            r="0"
            fill="rgba(103, 58, 183, 1)"
          />
          <circle
            id="position-circle-purple-shadow"
            className="position-shadow"
            cx="30%"
            cy="35%"
            r="0"
            fill="rgba(103, 58, 183, 0)"
          />

          <circle
            id="position-circle-red"
            className="position-circle"
            cx="70%"
            cy="35%"
            r="0"
            fill="rgba(244, 67, 54, 1)"
          />
          <circle
            id="position-circle-red-shadow"
            className="position-shadow"
            cx="70%"
            cy="35%"
            r="0"
            fill="rgba(244, 67, 54, 0)"
          />

          <circle
            id="position-circle-green"
            className="position-circle"
            cx="35%"
            cy="70%"
            r="0"
            fill="rgba(76, 175, 80, 1)"
          />
          <circle
            id="position-circle-green-shadow"
            className="position-shadow"
            cx="35%"
            cy="70%"
            r="0"
            fill="rgba(76, 175, 80, 0)"
          />

          <circle
            id="position-circle-orange"
            className="position-circle"
            cx="67%"
            cy="52%"
            r="0"
            fill="rgba(255, 152, 0, 1)"
          />
          <circle
            id="position-circle-orange-shadow"
            className="position-shadow"
            cx="67%"
            cy="52%"
            r="0"
            fill="rgba(255, 152, 0, 0)"
          />
        </svg>
      )}
      {!props.desktop && (
        <svg id="title-svg-container" className="section-image-alt">
          <clipPath id="title-circle-clippath">
            <circle
              id="map-circle0"
              className="map-circle"
              cx="50%"
              cy="50%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle1"
              className="map-circle"
              cx="30%"
              cy="35%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle2"
              className="map-circle"
              cx="70%"
              cy="35%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle3"
              className="map-circle"
              cx="35%"
              cy="70%"
              r="0.1px"
              fill="#ffffff"
            />
            <circle
              id="map-circle4"
              className="map-circle"
              cx="67%"
              cy="52%"
              r="0.1px"
              fill="#ffffff"
            />
          </clipPath>

          <image
            id="title-map-alt"
            href={map}
            clipPath="url(#title-circle-clippath)"
          />

          <circle
            id="position-circle-blue"
            className="position-circle"
            cx="50%"
            cy="50%"
            r="10px"
            fill="rgba(25, 118, 210, 1)"
          />
          <circle
            id="position-circle-blue-shadow"
            className="position-shadow"
            cx="50%"
            cy="50%"
            r="0"
            fill="rgba(25, 118, 210, 0)"
          />

          <circle
            id="position-circle-purple"
            className="position-circle"
            cx="30%"
            cy="35%"
            r="0"
            fill="rgba(103, 58, 183, 1)"
          />
          <circle
            id="position-circle-purple-shadow"
            className="position-shadow"
            cx="30%"
            cy="35%"
            r="0"
            fill="rgba(103, 58, 183, 0)"
          />

          <circle
            id="position-circle-red"
            className="position-circle"
            cx="70%"
            cy="35%"
            r="0"
            fill="rgba(244, 67, 54, 1)"
          />
          <circle
            id="position-circle-red-shadow"
            className="position-shadow"
            cx="70%"
            cy="35%"
            r="0"
            fill="rgba(244, 67, 54, 0)"
          />

          <circle
            id="position-circle-green"
            className="position-circle"
            cx="35%"
            cy="70%"
            r="0"
            fill="rgba(76, 175, 80, 1)"
          />
          <circle
            id="position-circle-green-shadow"
            className="position-shadow"
            cx="35%"
            cy="70%"
            r="0"
            fill="rgba(76, 175, 80, 0)"
          />

          <circle
            id="position-circle-orange"
            className="position-circle"
            cx="67%"
            cy="52%"
            r="0"
            fill="rgba(255, 152, 0, 1)"
          />
          <circle
            id="position-circle-orange-shadow"
            className="position-shadow"
            cx="67%"
            cy="52%"
            r="0"
            fill="rgba(255, 152, 0, 0)"
          />
        </svg>
      )}
    </div>
  );
};

export default TitleImage;
