import React, { useContext } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";

import { AdjustMap, Focus, LocationMarker } from "../../components";
import PositionMarker from "../../components/Map/PositionMarker";
import { SessionContext } from "../../Session";
import "./map.css";

const ZOOM = 13; // Initial zoom distance

const Map = (props) => {
  const { route, map, setRoute, user, users } = useContext(SessionContext);

  const setStart = (value) => {
    setRoute({ ...route, start: value });
  };

  const setEnd = (value) => {
    setRoute({ ...route, end: value });
  };

  return (
    <div className="map-wrapper">
      <MapContainer center={map.position} zoom={ZOOM} scrollWheelZoom>
        <TileLayer
          className="test"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {route.start && (
          <LocationMarker
            value={
              user.start != null
                ? {
                    y: user.start[0],
                    x: user.start[1],
                    label: user.startLabel,
                  }
                : route.start
            }
            setValue={setStart}
            draggable={user.route == null}
            color={user.color}
          />
        )}
        {route.end && (
          <LocationMarker
            value={
              user.end != null
                ? {
                    y: user.end[0],
                    x: user.end[1],
                    label: user.endLabel,
                  }
                : route.end
            }
            setValue={setEnd}
            draggable={user.route == null}
            color={user.color}
          />
        )}
        {users.map((user) => {
          return (
            <div key={user.userId}>
              {user.location != null && (
                <PositionMarker position={user.location} color={user.color} />
              )}
              {user.start != null && (
                <LocationMarker
                  value={{
                    y: user.start[0],
                    x: user.start[1],
                    label: user.startLabel,
                  }}
                  setValue={setStart}
                  draggable={false}
                  color={user.color}
                />
              )}
              {user.end != null && (
                <LocationMarker
                  value={{
                    y: user.end[0],
                    x: user.end[1],
                    label: user.endLabel,
                  }}
                  setValue={setEnd}
                  draggable={false}
                  color={user.color}
                />
              )}
              {user.route != null && (
                <Polyline
                  pathOptions={{ color: user.color }}
                  positions={user.route}
                />
              )}
            </div>
          );
        })}
        <Focus />
        <AdjustMap desktop={props.desktop} />
        <PositionMarker position={map.position} color={user.color} />
        {/* <PositionMarker position={[-37.817290173198984, 144.9674614109535]} color="#1976d2" />
        <PositionMarker position={[-37.81317084827804, 144.96827664773693]} color="#673ab7" />
        <PositionMarker position={[-37.814628718584956, 144.96171054776403]} color="#f44336" />
        <PositionMarker position={[-37.81486607495625, 144.96619528798573]} color="#4caf50" />
        <PositionMarker position={[-37.81128921014993, 144.96190379983207]} color="#ff9800" /> */}

        {user.route != null && (
          <Polyline
            pathOptions={{ color: user.color }}
            positions={user.route}
          />
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
