// Initial users state.
export const initial = [];

/**
 * Updates user state, returning new state.
 * @param {*} state Current session state.
 * @param {*} action Non-exhaustive list of updates to client state, former values will be retained if not specified.
 * @example { sessionId: null, ... }
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case "SESSION":
      return action.users;
    case "CONNECT":
      return [
        ...state,
        {
          userId: action.userId,
          name: action.name,
          color: action.color,
        },
      ];
    case "LOCATION":
      for (let user of state) {
        if (user.userId === action.userId) {
          user.location = action.location;
          user.locationName = action.locationName;
          user.progress = action.progress;
          break;
        }
      }
      return state;
    case "SET_ROUTE":
      for (let user of state) {
        if (user.userId === action.userId) {
          user.start = action.start;
          user.end = action.end;
          user.route = action.route;
          user.progress = action.progress;
          user.bbox = action.bbox;
          user.startLabel = action.startLabel;
          user.endLabel = action.endLabel;
          break;
        }
      }
      return state;
    case "CANCEL_ROUTE":
      for (let user of state) {
        if (user.userId === action.userId) {
          user.start = null;
          user.end = null;
          user.route = null;
          user.progress = null;
          user.bbox = null;
          user.startLabel = null;
          user.endLabel = null;
          break;
        }
      }
      return state;
    case "KICK":
      return state.filter((user) => user.userId !== action.kickedUserId);
    case "DISCONNECT":
      return state.filter((user) => user.userId !== action.userId);
    default:
      return state;
  }
};
