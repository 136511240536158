import React, { useState } from "react";

import { RoomDialog } from "./components";
import { Body } from "./containers";

import "./home.css";

const Home = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="home">
      <RoomDialog setSessionId={props.setSessionId} open={open} handleClose={handleClose} />
      <Body desktop={props.desktop} handleOpen={handleOpen} />
    </div>
  );
};

export default Home;
