import React from "react";
import "./body.css";

import DesktopBody from "./DesktopBody";
import MobileBody from "./MobileBody";

const Body = (props) => {
  return (
    <div>
      {props.desktop && (
        <DesktopBody desktop={props.desktop} handleOpen={props.handleOpen} />
      )}
      {!props.desktop && (
        <MobileBody desktop={props.desktop} handleOpen={props.handleOpen} />
      )}
    </div>
  );
};

export default Body;
