import { getPosition, posToCoords } from "../util/Location";

const url = process.env.REACT_APP_PHOTON_PROVIDER;

class SearchAPIError extends Error {
  constructor() {
    super("Failed to search for location.");
  }
}

export const searchLocation = async (query) => {
  const pos = posToCoords(await getPosition());
  let res;

  try {
    res = await fetch(`${url}/api/?q=${query}&lat=${pos[0]}&lon=${pos[1]}`);
  } catch (error) {
    throw new SearchAPIError();
  }

  return mapResponse(await res.json());
};

export const searchCoords = async (coords) => {
  const pos = coords.split(", ");
  let res;

  try {
    res = await fetch(`${url}/reverse?lat=${pos[0]}&lon=${pos[1]}`);
  } catch (error) {
    throw new SearchAPIError();
  }

  return mapResponse(await res.json());
};

const mapResponse = (res) => {
  return res.features.map((x) => ({
    key: `${x.geometry.coordinates[1]},${x.geometry.coordinates[0]}`,
    x: x.geometry.coordinates[0],
    y: x.geometry.coordinates[1],
    label: createLabel(x.properties),
    search: false,
  }));
};

const createLabel = (data) => {
  let label = [];
  if (data.name != null) label.push(data.name);
  if (data.street != null) label.push(data.street);
  if (data.district != null) label.push(data.district);
  if (data.city != null) label.push(data.city);
  if (data.county != null) label.push(data.county);
  if (data.state != null) label.push(data.state);
  if (data.country != null) label.push(data.country);
  if (data.postcode != null) label.push(data.postcode);
  return label.join(", ");
};
