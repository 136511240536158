import React, { useContext, useEffect, useState } from "react";

import "./appbar.css";
import DesktopAppBar from "./DesktopAppBar";
import MobileAppBar from "./MobileAppBar";

import { setRoute as setUserRoute, cancelRoute } from "../../../../api/server";
import { SessionContext } from "../../Session";
import { useSnackbar } from "notistack";
import { searchCoords } from "../../../../api/search";

const MAX_SEARCHES = 2;

const AppBar = (props) => {
  const { session, route, user, setRoute, setClient, setMap } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();

  const [numSearches, setNumSearches] = useState(0);

  useEffect(() => {
    setRoute({ started: false, canceled: false });
  }, [user.route, setRoute]);

  const setStart = (value) => {
    setRoute({ start: value });
  };

  const setEnd = (value) => {
    setRoute({ end: value });
  };

  /**
   * Handles menu button pressed.
   */
  const handleMenuClicked = () => {
    setClient({ drawer: true });
    setMap({ autoFocus: false });
  };

  const handleStart = async () => {
    try {
      if (numSearches >= MAX_SEARCHES) throw new Error(`Maximum of ${MAX_SEARCHES} route searches available.`);

      const count = numSearches + 1;
      enqueueSnackbar(`${MAX_SEARCHES - count} route searches remaining.`, {
        variant: "info",
      });
      setNumSearches(count);
      setRoute({ started: true });

      let startLabel = `${route.start.y}, ${route.start.x}`;
      let endLabel = `${route.end.y}, ${route.end.x}`;

      const startResults = await searchCoords(`${route.start.y}, ${route.start.x}`);
      const endResults = await searchCoords(`${route.end.y}, ${route.end.x}`);

      if (startResults) startLabel = startResults[0].label;
      if (endResults) endLabel = endResults[0].label;

      props.sendJsonMessage(
        setUserRoute(session.sessionId, {
          start: [route.start.y, route.start.x],
          end: [route.end.y, route.end.x],
          startLabel,
          endLabel,
        })
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setRoute({ started: false });
    }
  };

  const handleCancel = async () => {
    try {
      setRoute({ canceled: true });
      props.sendJsonMessage(cancelRoute(session.sessionId));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setRoute({ canceled: false });
    }
  };

  const appBarProps = {
    setStart,
    setEnd,
    handleMenuClicked,
    handleStart,
    handleCancel,
  };

  return (
    <div>
      {props.desktop && <DesktopAppBar {...appBarProps} />}
      {!props.desktop && <MobileAppBar {...appBarProps} />}
    </div>
  );
};

export default AppBar;
