import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createSession, findSession } from "../../../../api/server";

const RoomDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [roomId, setRoomId] = useState("");

  const navigate = useNavigate();

  const handleJoinRoom = async (e) => {
    try {
      e.preventDefault();
      const result = (await findSession(roomId)).found;
      if (!result) throw new Error("Room does not exist.");
      props.setSessionId(roomId);
      navigate(`/${roomId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleCreateRoom = async () => {
    try {
      const roomId = (await createSession()).sessionId;
      props.setSessionId(roomId);
      navigate(`/${roomId}`);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleTextChange = (e) => {
    setRoomId(e.target.value);
  };

  return (
    <Dialog className="option-dialog" open={props.open} onClose={props.handleClose}>
      <DialogTitle>navshare</DialogTitle>
      <DialogContent>
        <form className="form-input" id="join-room" onSubmit={handleJoinRoom}>
          <TextField
            required
            fullWidth
            id="room-id"
            label="Room ID"
            onChange={handleTextChange}
            value={roomId}
          ></TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth form="join-room" type="submit">
          Join Room
        </Button>
      </DialogActions>
      <br />
      <DialogContentText>Don't have a room?</DialogContentText>
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={handleCreateRoom}>
          Create Room
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoomDialog;
