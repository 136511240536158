const url = process.env.REACT_APP_BACK_URL;
const apiKey = process.env.REACT_APP_API_KEY;

class ConnectionError extends Error {
  constructor() {
    super("Failed to connect to server.");
  }
}

export const createSession = async () => {
  let res;

  try {
    res = await fetch(`${url}`, {
      method: "POST",
      headers: {
        "x-api-key": apiKey,
      },
    });
  } catch (error) {
    throw new ConnectionError();
  }

  const resData = await res.json();
  if (!res.ok) throw new Error(resData.message);
  return resData;
};

export const findSession = async (sessionId) => {
  let res;

  try {
    res = await fetch(`${url}?sessionId=${sessionId}`, {
      method: "GET",
      headers: {
        "x-api-key": apiKey,
      },
    });
  } catch (error) {
    throw new ConnectionError();
  }

  const resData = await res.json();
  if (!res.ok) throw new Error(resData.message);
  return resData;
};

export const deleteSession = (sessionId) => {
  return {
    data: {
      type: "DELETE_SESSION",
      sessionId,
    },
  };
};

export const lockSession = (sessionId) => {
  return {
    data: {
      type: "LOCK",
      sessionId,
    },
  };
};

export const unlockSession = (sessionId) => {
  return {
    data: {
      type: "UNLOCK",
      sessionId,
    },
  };
};

export const updateUser = (sessionId, data) => {
  return {
    data: {
      type: "LOCATION",
      sessionId,
      ...data,
    },
  };
};

export const setRoute = (sessionId, data) => {
  return {
    data: {
      type: "SET_ROUTE",
      sessionId,
      ...data,
    },
  };
};

export const cancelRoute = (sessionId) => {
  return {
    data: {
      type: "CANCEL_ROUTE",
      sessionId,
    },
  };
};

export const connect = (sessionId, data) => {
  return {
    data: {
      type: "CONNECT",
      sessionId,
      ...data,
    },
  };
};

export const kick = (sessionId, data) => {
  return {
    data: {
      type: "KICK",
      sessionId,
      ...data,
    },
  };
};
