import { CircularProgress } from "@mui/material";
import React, { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { useSnackbar } from "notistack";
import useWebSocket from "react-use-websocket";

import { searchCoords } from "../../api/search";
import { findSession, updateUser } from "../../api/server";
import {
  clientReducer,
  initialClient,
  initialMap,
  initialRoute,
  initialSession,
  initialUser,
  initialUsers,
  mapReducer,
  routeReducer,
  sessionReducer,
  userReducer,
  usersReducer,
} from "../../reducers/_index";
import { getPosition, posToCoords } from "../../util/Location";
import { ConnectDialog, DisconnectDialog, HelpDialog } from "./components";
import { AppBar, MainContent, Map, MenuDrawer } from "./containers";
import "./session.css";
import { useNavigate } from "react-router-dom";

/**
 * Context for all components in a single session.
 * Holds data for: client components, map (markers, focus, etc.), route start/end, session data from server.
 */
export const SessionContext = createContext();

const POSITION_INTERVAL = 1000; // Refresh interval for user's position.
const UPDATE_INTERVAL = 5000; // Refresh interval for server calls.
const LOCATION_INTERVAL = 60000; // Refresh interval for determining and sending user location to server.

const socketUrl = process.env.REACT_APP_WSS_URL;

/**
 * Session component.
 * @param {Object} props desktop, sessionId
 * @returns React component
 */
const Session = (props) => {
  // Reducers for session state.
  const [client, setClient] = useReducer(clientReducer, initialClient);
  const [map, setMap] = useReducer(mapReducer, initialMap);
  const [route, setRoute] = useReducer(routeReducer, initialRoute);
  const [session, setSession] = useReducer(sessionReducer, initialSession);
  const [user, setUser] = useReducer(userReducer, initialUser);
  const [users, setUsers] = useReducer(usersReducer, initialUsers);

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl);

  const { enqueueSnackbar } = useSnackbar();

  // True when session data received.
  const [connected, setConnected] = useState(false);

  const navigate = useNavigate();

  // Values/dispatchers used throughout the session.
  const contextValue = {
    client,
    map,
    route,
    session,
    user,
    users,
    setClient,
    setMap,
    setRoute,
    setSession,
    setUser,
    setUsers,
  };

  const getPath = () => {
    return window.location.pathname.substring(1);
  };

  const checkValidSession = useCallback(async () => {
    try {
      const found = (await findSession(getPath())).found;
      if (!found) {
        navigate("/");
        throw new Error("Room does not exist.");
      } else {
        setClient({ connect: true });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, navigate]);

  const processMessage = useCallback(
    (message) => {
      switch (message.type) {
        case "SESSION":
          if (session.userId == null) {
            setSession(message);
            setUser(message);
            setUsers(message);
            enqueueSnackbar("Joined room.", { variant: "success" });
            setConnected(true);
          }
          break;
        case "LOCK":
          setSession(message);
          enqueueSnackbar("Room locked.", { variant: "info" });
          break;
        case "UNLOCK":
          setSession(message);
          enqueueSnackbar("Room unlocked.", { variant: "info" });
          break;
        case "CONNECT":
          if (session.userId != null && message.userId !== session.userId) {
            setUsers(message);
            enqueueSnackbar(`${message.name} joined the room.`, { variant: "info" });
          }
          break;
        case "LOCATION":
          if (message.userId === session.userId) {
            setUser(message);
          } else {
            setUsers(message);
          }
          break;
        case "SET_ROUTE":
          if (message.userId === session.userId) {
            setUser(message);
          } else {
            setUsers(message);
            enqueueSnackbar(`${message.name} set a route.`, { variant: "info" });
          }
          break;
        case "CANCEL_ROUTE":
          if (message.userId === session.userId) {
            setUser(message);
          } else {
            setUsers(message);
          }
          break;
        case "KICK":
          if (message.kickedUserId === session.userId) {
            navigate("/");
            enqueueSnackbar("You have been kicked.", { variant: "error" });
          } else {
            setSession(message);
            setUsers(message);
            enqueueSnackbar(`${message.name} kicked.`, { variant: "warning" });
          }
          break;
        case "DELETE_SESSION":
          navigate("/");
          enqueueSnackbar("Room closed.", { variant: "error" });
          break;
        case "DISCONNECT":
          if (message.userId === session.userId) {
            navigate("/");
          } else {
            setSession(message);
            setUsers(message);
            enqueueSnackbar(`${message.name} disconnected.`, { variant: "warning" });
          }
          break;
        case "ERROR":
          switch (message.errorType) {
            case "CONNECT":
              setClient({ connect: true });
              break;
            case "SET_ROUTE":
              setRoute({ started: false });
              break;
            case "CANCEL_ROUTE":
              setRoute({ canceled: false });
              break;
            default:
              break;
          }
          enqueueSnackbar(message.message, { variant: "error" });
          break;
        default:
          setRoute({ started: false, canceled: false });
          enqueueSnackbar(message.message, { variant: "error" });
          break;
      }
    },
    [enqueueSnackbar, navigate, session.userId]
  );

  useEffect(() => {
    let checked = false;
    if (!checked) checkValidSession();
    return () => {
      checked = true;
    };
  }, [checkValidSession]);

  useEffect(() => {
    if (lastJsonMessage != null) {
      processMessage(lastJsonMessage);
    }
  }, [lastJsonMessage, processMessage]);

  // Session timers.
  // Update position every 1 second.
  // Update/fetch session data every 5 seconds.
  // Update user location every 60 seconds.
  useEffect(() => {
    let position = null;

    const updatePosition = setInterval(async () => {
      if (!connected) return;
      position = posToCoords(await getPosition());
      setMap({ position });
    }, POSITION_INTERVAL);

    const updateData = setInterval(async () => {
      if (!connected || position == null) return;
      try {
        sendJsonMessage(updateUser(props.sessionId || getPath(), { location: position }));
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }, UPDATE_INTERVAL);

    const updateLocation = setInterval(async () => {
      if (!connected || position == null) return;

      try {
        const locations = await searchCoords(`${position[0]}, ${position[1]}`);
        if (locations) {
          const locationName = locations[0].label;
          sendJsonMessage(updateUser(props.sessionId || getPath(), { locationName }));
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }, LOCATION_INTERVAL);

    return () => {
      clearInterval(updatePosition);
      clearInterval(updateData);
      clearInterval(updateLocation);
    };
  }, [connected, props.sessionId, setMap, enqueueSnackbar, sendJsonMessage]);

  return (
    <SessionContext.Provider value={contextValue}>
      <div id="session">
        {(!connected || map.position == null) && (
          <div id="disconnected">
            <ConnectDialog
              sessionId={props.sessionId || getPath()}
              setConnected={setConnected}
              sendJsonMessage={sendJsonMessage}
            />
            <CircularProgress />
          </div>
        )}
        {connected && map.position != null && (
          <div className="session-main">
            <MainContent open={client.drawer}>
              <AppBar desktop={props.desktop} sendJsonMessage={sendJsonMessage} />
              <Map desktop={props.desktop} />
            </MainContent>
            <MenuDrawer desktop={props.desktop} sendJsonMessage={sendJsonMessage} />
            <HelpDialog />
            <DisconnectDialog sendJsonMessage={sendJsonMessage} />
          </div>
        )}
      </div>
    </SessionContext.Provider>
  );
};

export default Session;
