import { useLeafletContext } from "@react-leaflet/core";
// eslint-disable-next-line
import L from "leaflet"; // Required for leaflet-active-area
import "leaflet-active-area";
import { useSnackbar } from "notistack";
import { useContext, useEffect } from "react";
import { SessionContext } from "../../Session";

/**
 * Listens for changes to client (drawer) state.
 */
const AdjustMap = (props) => {
  const { client } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();
  const context = useLeafletContext();

  // Changes map active area when drawer open.
  useEffect(() => {
    try {
      if (client.drawer && props.desktop) {
      context.map.setActiveArea({
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "20vw",
        height: "100%",
      });
    } else {
      context.map.setActiveArea({
        position: "absolute",
        top: "0px",
        left: "0px",
        right: "100%",
        height: "100%",
      });
    }
  } catch (error) {
    enqueueSnackbar("Map adjustment failed.", { variant: "error" });
  }
  }, [props, client.drawer, context, enqueueSnackbar]);

  return null;
};

export default AdjustMap;
