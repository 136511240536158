import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Button, Divider, Tooltip } from "@mui/material";
import React, { useContext } from "react";

import { SessionContext } from "../../Session";
import "./userinfo.css";
import UserProgress from "./UserProgress";

/**
 * User card for each connected user.
 * @param {Object} props name, bounds, progress, locationName, handleKick
 * @returns React component
 */
const UserInfo = (props) => {
  const { setMap, setClient, session } = useContext(SessionContext);

  const handleClick = (e) => {
    e.stopPropagation();
    if (props.bounds != null) setMap({ bounds: [props.bounds[0], props.bounds[1]] });
    if (!props.desktop) setClient({ drawer: false });
  };

  const handleFocus = (e) => {
    e.stopPropagation();
    setMap({ focus: props.position });
    if (!props.desktop) setClient({ drawer: false });
  };

  return (
    <div className="user-info" onClick={handleClick}>
      <div className="user-header">
        <div className="user-name">
          <p>{`${props.name}`}</p>
        </div>
        <div className="user-options-div">
          <Tooltip title="Location" arrow>
            <Button className="kick-button" disableRipple onClick={handleFocus}>
              <MyLocationIcon className="kick-button-icon" />
            </Button>
          </Tooltip>
          {session.owner && (
            <Tooltip title="Kick" arrow>
              <Button className="kick-button" disableRipple onClick={() => props.handleKick(props.userId)}>
                <PersonRemoveIcon className="kick-button-icon" />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="user-location">
        <LocationOnIcon className="user-location-icon" />
        <p>{`${props.locationName}`}</p>
      </div>
      <UserProgress value={props.progress} color={props.color} />
      <Divider light />
    </div>
  );
};

export default UserInfo;
