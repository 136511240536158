// Initial client state.
// Specifies open components.
export const initial = {
  connect: false,
  disconnect: false,
  drawer: false,
};

/**
 * Updates client state, returning new state.
 * @param {*} state Current client state.
 * @param {*} action Non-exhaustive list of updates to client state, former values will be retained if not specified.
 * @example { connect: true, ... }
 */
export const reducer = (state, action) => {
  return { ...state, ...action };
};
