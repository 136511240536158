import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "../../../../api/server";
import { getPosition, posToCoords } from "../../../../util/Location";
import { SessionContext } from "../../Session";

/**
 * Connection dialog component. Handles user connection to session.
 * @param {Object} props sessionId, setConnected
 * @returns React component
 */
const ConnectDialog = (props) => {
  const { setMap, setClient, client } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const navigate = useNavigate();

  /**
   * Update name field on connection.
   * @param {Event} e
   */
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  /**
   * Connects to session without refreshing page.
   * @param {Event} e event
   */
  const handleConnect = async (e) => {
    try {
      e.preventDefault();
      setClient({ connect: false });
      props.sendJsonMessage(connect(props.sessionId, { name }));

      const position = posToCoords(await getPosition());

      setMap({ position });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setClient({ connect: true });
    }
  };

  /**
   * Navigate to root without establishing connection.
   */
  const handleCancel = () => {
    navigate("/");
  };

  return (
    <Dialog className="option-dialog" open={client.connect}>
      <DialogTitle>Name Select</DialogTitle>
      <DialogContent>
        <form className="form-input" id="connect" onSubmit={handleConnect}>
          <TextField required fullWidth id="name" label="Name" onChange={handleNameChange} value={name}></TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" fullWidth form="connect" type="submit">
          Connect
        </Button>
      </DialogActions>
      <DialogActions>
        <Button variant="outlined" color="error" fullWidth onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectDialog;
