// Initial user state.
export const initial = {
  userId: null,
  name: null,
  color: null,
  location: null,
  locationName: null,
  route: null,
  progress: null,
  bbox: null,
  start: null,
  end: null,
  startLabel: null,
  endLabel: null,
};

/**
 * Updates user state, returning new state.
 * @param {*} state Current session state.
 * @param {*} action Non-exhaustive list of updates to client state, former values will be retained if not specified.
 * @example { sessionId: null, ... }
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case "SESSION":
      return {
        userId: action.user.userId,
        name: action.user.name,
        color: action.user.color,
        location: action.user.location,
        locationName: action.user.locationName,
        route: action.user.route,
        progress: action.user.progress,
        bbox: action.user.bbox,
        start: action.user.start,
        end: action.user.end,
        startLabel: action.user.startLabel,
        endLabel: action.user.endLabel,
      };
    case "LOCATION":
      return {
        ...state,
        location: action.location,
        locationName: action.locationName,
        progress: action.progress,
      };
    case "SET_ROUTE":
      return {
        ...state,
        start: action.start,
        end: action.end,
        route: action.route,
        progress: action.progress,
        bbox: action.bbox,
        startLabel: action.startLabel,
        endLabel: action.endLabel,
      };
    case "CANCEL_ROUTE":
      return {
        ...state,
        start: undefined,
        end: undefined,
        route: undefined,
        progress: undefined,
        bbox: undefined,
        startLabel: undefined,
        endLabel: undefined,
      };
    default:
      return state;
  }
};
