import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { kick, lockSession, unlockSession } from "../../../../api/server";
import { SessionContext } from "../../Session";
import DesktopMenuDrawer from "./DesktopMenuDrawer";

import "./menudrawer.css";
import MobileMenuDrawer from "./MobileMenuDrawer";

const MenuDrawer = (props) => {
  const { setClient, setMap, session } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setClient({ drawer: false });
    setMap({ autoFocus: false });
  };

  const handleDisconnect = () => {
    setClient({ disconnect: true });
  };

  const handleKick = async (userId) => {
    try {
      props.sendJsonMessage(kick(session.sessionId, { userId }));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleLock = async () => {
    try {
      props.sendJsonMessage(lockSession(session.sessionId));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleUnlock = async () => {
    try {
      props.sendJsonMessage(unlockSession(session.sessionId));
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const drawerProps = {
    handleDisconnect,
    handleKick,
    handleLock,
    handleUnlock,
    handleClose,
  };

  return (
    <div>
      {props.desktop && <DesktopMenuDrawer {...drawerProps} />}
      {!props.desktop && <MobileMenuDrawer {...drawerProps} />}
    </div>
  );
};

export default MenuDrawer;
