import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { deleteSession } from "../../../../api/server";
import { SessionContext } from "../../Session";

/**
 * Disconnection dialog component. Handles user connection disconnecting from session.
 * @returns React component
 */
const DisconnectDialog = (props) => {
  const { setClient, client, session } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  /**
   * Removes dialog from render.
   */
  const handleCancel = () => {
    setClient({ disconnect: false });
  };

  /**
   * Removes user from session and redirects to root.
   */
  const handleConfirm = async () => {
    try {
      navigate("/");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const handleDelete = async () => {
    try {
      props.sendJsonMessage(deleteSession(session.sessionId));
      navigate("/");
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  return (
    <Dialog open={client.disconnect} onClose={handleCancel}>
      <DialogTitle>Disconnect from room?</DialogTitle>
      <DialogActions>
        <Button variant="contained" color="error" fullWidth onClick={handleConfirm} startIcon={<LogoutIcon />}>
          Disconnect
        </Button>
      </DialogActions>
      {session.owner && (
        <DialogActions>
          <Button variant="contained" color="error" fullWidth onClick={handleDelete} startIcon={<DeleteIcon />}>
            Delete Room
          </Button>
        </DialogActions>
      )}
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={handleCancel}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisconnectDialog;
