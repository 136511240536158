import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const UserProgress = (props) => {
  return (
    <div>
      {props.value == null && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "8px",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress sx={{ "& .MuiLinearProgress-bar": { backgroundColor: props.color }, backgroundColor: "#d6d6d6" }} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
              ?%
            </Typography>
          </Box>
        </Box>
      )}
      {props.value != null && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "8px",
          }}
        >
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              value={props.value}
              sx={{ "& .MuiLinearProgress-bar": { backgroundColor: props.color }, backgroundColor: "#d6d6d6" }}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              color="text.secondary"
            >{`${props.value}%`}</Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default UserProgress;
