import React, { useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

import {
  blueMarker,
  purpleMarker,
  redMarker,
  greenMarker,
  orangeMarker,
} from "../../../../assests";

/**
 * Marker for position.
 * @param {Object} props draggable, value, setValue
 * @returns React-leaflet marker component
 */
const LocationMarker = (props) => {
  const ref = useRef(null);
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    let iconUrl = null;

    switch (props.color) {
      case "#1976d2":
        iconUrl = blueMarker;
        break;
      case "#673ab7":
        iconUrl = purpleMarker;
        break;
      case "#f44336":
        iconUrl = redMarker;
        break;
      case "#4caf50":
        iconUrl = greenMarker;
        break;
      case "#ff9800":
        iconUrl = orangeMarker;
        break;
      default:
        break;
    }

    if (iconUrl != null) {
      setIcon(
        L.icon({
          iconUrl,
          iconAnchor: [16, 45],
          popupAnchor: [0, -30],
          iconSize: [32, 45],
        })
      );
    }
  }, [props.color]);

  /**
   * Handles marker drag observer.
   */
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = ref.current;
        if (marker != null) {
          const x = marker.getLatLng().lng;
          const y = marker.getLatLng().lat;
          props.setValue({
            key: `${y},${x}`,
            x,
            y,
            label: `${y}, ${x}`,
            search: true,
          });
        }
      },
    }),
    [props]
  );

  return (
    <div>
      {icon != null && (
        <Marker
          draggable={props.draggable}
          eventHandlers={eventHandlers}
          ref={ref}
          position={[props.value.y, props.value.x]}
          icon={icon}
        >
          <Popup autoPan={false}>{props.value.label}</Popup>
        </Marker>
      )}
    </div>
  );
};

export default LocationMarker;
