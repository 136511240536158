import { Button } from "@mui/material";
import React from "react";
import GitHubIcon from "@mui/icons-material/GitHub";
import { GridBackground, TitleImage } from "../../components";

const MobileBody = (props) => {
  return (
    <main id="home-body">
      <div id="title-section" className="body-section-alt">
        <div id="title-left" className="section-left">
          <GridBackground desktop={props.desktop} />
          <TitleImage desktop={props.desktop} />
        </div>
        <div id="title-right" className="section-right">
          <div id="title-content" className="section-content">
            <div id="text0" className="section-text">
              <h1>navshare</h1>
            </div>
            <div id="text1" className="section-text">
              <h2>A shared navigation project</h2>
              <h3>Follow routes set by you or others.</h3>
            </div>
            <div id="text2" className="section-text">
              <h2>Never lose your friends again</h2>
              <h3>Join a room with up to 5 people.</h3>
            </div>
            <div id="text3" className="section-text">
              <h2>Usable on desktop and mobile devices</h2>
              <h3>Access the app directly from your favourite browser.</h3>
            </div>
          </div>
          <div id="title-footer" className="section-footer">
            <Button onClick={props.handleOpen}>Get Started</Button>
            <div id="home-footer">
              <p>navshare by Emmanuel Pinca</p>
              <div>
                <GitHubIcon id="github-icon" />
                <a href="https://github.com/emmanuel-p-1">GitHub</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MobileBody;
