import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MobileStepper,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import {
  openMenuGif,
  setRouteGif,
  shareGif
} from "../../../../assests";

import "./helpdialog.css";

const content = [
  <div className="help-content">
    <img
      className="help-image"
      src={setRouteGif}
      alt="Set Route"
    />
    <br />
    <h3>Set your route</h3>
  </div>,
  <div className="help-content">
    <img
      className="help-image"
      src={openMenuGif}
      alt="Open Menu"
    />
    <br />
    <h3>View other users</h3>
  </div>,
  <div className="help-content">
    <img
      className="help-image"
      src={shareGif}
      alt="Share Room ID"
    />
    <br />
    <h3>Share the room ID to invite others</h3>
  </div>,
];

const HelpDialog = () => {
  const [open, setOpen] = useState(true);
  const [step, setStep] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const incrementStep = () => {
    setStep((num) => num + 1);
  };

  const decrementStep = () => {
    setStep((num) => num - 1);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>{content[step]}</DialogContent>
      <DialogActions>
        <MobileStepper
          variant="dots"
          steps={content.length}
          position="static"
          activeStep={step}
          sx={{ flexGrow: 1 }}
          nextButton={
            <Button
              onClick={incrementStep}
              disabled={step === content.length - 1}
            >
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button onClick={decrementStep} disabled={step === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;
