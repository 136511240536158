import { divIcon } from "leaflet";
import React, { useEffect, useState } from "react";
import { Marker } from "react-leaflet";

const PositionMarker = (props) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    switch (props.color) {
      case "#1976d2":
        setIcon(divIcon({ className: "position-circle-blue" }));
        break;
      case "#673ab7":
        setIcon(divIcon({ className: "position-circle-purple" }));
        break;
      case "#f44336":
        setIcon(divIcon({ className: "position-circle-red" }));
        break;
      case "#4caf50":
        setIcon(divIcon({ className: "position-circle-green" }));
        break;
      case "#ff9800":
        setIcon(divIcon({ className: "position-circle-orange" }));
        break;
      default:
        break;
    }
  }, [props.color]);

  return (
    <div>
      {icon != null && (
        <Marker position={props.position} icon={icon} />
      )}
    </div>
  );
};

export default PositionMarker;
