// Initial session state.
// Updated after every fetch from server.
export const initial = {
  sessionId: null,
  userId: null,
  numConnections: null,
  owner: null,
  locked: null,
  expireTime: null,
};

/**
 * Updates session state, returning new state.
 * @param {*} state Current session state.
 * @param {*} action Non-exhaustive list of updates to client state, former values will be retained if not specified.
 * @example { sessionId: null, ... }
 */
export const reducer = (state, action) => {
  switch (action.type) {
    case "SESSION":
      return {
        sessionId: action.sessionId,
        userId: action.userId,
        numConnections: action.numConnections,
        owner: action.owner === action.userId,
        locked: action.locked,
        expireTime: action.expireTime,
      };
    case "LOCK":
      return { ...state, locked: true };
    case "UNLOCK":
      return { ...state, locked: false };
    case "KICK":
      return { ...state, owner: action.owner === state.userId };
    case "DISCONNECT":
      return { ...state, owner: action.owner === state.userId };
    default:
      return state;
  }
};
