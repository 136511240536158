// Initial map state.
export const initial = {
  position: null,
  autoFocus: false,
  bounds: null,
  focus: null,
};

/**
 * Updates map state, returning new state.
 * @param {*} state Current map state.
 * @param {*} action Non-exhaustive list of updates to map state, former values will be retained if not specified.
 * @example { position: null, ... }
 */
export const reducer = (state, action) => {
  return { ...state, ...action };
};
