import { Divider, Drawer } from "@mui/material";
import React, { useContext } from "react";

import { UserInfo } from "../../components";
import { SessionContext } from "../../Session";
import MenuFooter from "./MenuFooter";
import MenuHeader from "./MenuHeader";

const MobileMenuDrawer = (props) => {
  const { client, session, map, user, users } = useContext(SessionContext);

  return (
    <Drawer className="menu-drawer-alt" variant="persistent" anchor="bottom" open={client.drawer}>
      <MenuHeader
        alt={true}
        handleClose={props.handleClose}
        sessionId={session.sessionId}
        handleDisconnect={props.handleDisconnect}
      />
      <Divider />
      <div className="drawer-body">
        <UserInfo
          key={user.userId}
          userId={user.userId}
          name={user.name}
          locationName={user.locationName}
          progress={user.progress}
          handleKick={props.handleKick}
          bounds={user.bbox}
          position={map.position}
          color={user.color}
          desktop={false}
        />
        {users.map((user) => {
          return (
            <UserInfo
              key={user.userId}
              userId={user.userId}
              name={user.name}
              locationName={user.locationName}
              progress={user.progress}
              handleKick={props.handleKick}
              bounds={user.bbox}
              position={user.location}
              color={user.color}
              desktop={false}
            />
          );
        })}
      </div>
      <Divider />
      <MenuFooter
        connections={users.length + 1}
        locked={session.locked}
        handleLock={props.handleLock}
        handleUnlock={props.handleUnlock}
        time={session.expireTime}
      />
    </Drawer>
  );
};

export default MobileMenuDrawer;
