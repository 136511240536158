import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { Home, Session } from "./pages";
import "./App.css";

const MIN_DESKTOP_WIDTH = 900;

const App = () => {
  const [isDesktop, setIsDesktop] = useState(null); // Layout state
  const [sessionId, setSessionId] = useState(null);

  const onResize = useCallback(() => {
    if (window.innerWidth <= MIN_DESKTOP_WIDTH) {
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
    }
  }, [setIsDesktop]);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [onResize]);

  return (
    <SnackbarProvider maxSnack={3}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                desktop={isDesktop == null ? window.innerWidth > MIN_DESKTOP_WIDTH : isDesktop}
                setSessionId={setSessionId}
              />
            }
          />
          <Route
            path="/:id"
            element={
              <Session
                desktop={isDesktop == null ? window.innerWidth > MIN_DESKTOP_WIDTH : isDesktop}
                sessionId={sessionId}
              />
            }
          />
        </Routes>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
