import MyLocationIcon from "@mui/icons-material/MyLocation";
import React, { useCallback, useContext, useEffect } from "react";
import { useMap } from "react-leaflet";
import { SessionContext } from "../../Session";

/**
 * Handles map focus.
 * @returns React component
 */
const Focus = () => {
  const { map, setMap } = useContext(SessionContext);
  const leafletMap = useMap();

  /**
   * Toggles recentering to user position.
   */
  const handleRecenter = () => {
    setMap({ autoFocus: !map.autoFocus });
  };

  /**
   * Recenter button - toggles autofocus.
   * @returns Recenter button component
   */
  const Button = () => {
    return (
      <button className="recenter-button" onClick={handleRecenter}>
        <MyLocationIcon />
      </button>
    );
  };

  /**
   * Turn off autofocus when map moved.
   */
  const onDrag = useCallback(() => {
    setMap({ autoFocus: false });
  }, [setMap]);

  // Turns on listener when autoFocus is on.
  useEffect(() => {
    if (map.autoFocus) {
      leafletMap.on("drag", onDrag);
      leafletMap.setView(map.position);
      return () => {
        leafletMap.off("drag", onDrag);
      };
    }
  }, [leafletMap, map, onDrag]);

  // Fits map to bounds when set.
  useEffect(() => {
    if (map.bounds != null) leafletMap.fitBounds(map.bounds);
  }, [leafletMap, map.bounds]);

  // Moves map to focus point when set.
  useEffect(() => {
    if (map.focus != null) leafletMap.setView(map.focus);
  }, [leafletMap, map.focus]);

  return (
    <div className="leaflet-top leaflet-left">
      {!map.autoFocus && (
        <div className="recenter-unpressed">
          <div className="leaflet-control leaflet-bar">
            <Button />
          </div>
        </div>
      )}
      {map.autoFocus && (
        <div className="recenter-pressed">
          <div className="leaflet-control leaflet-bar">
            <Button />
          </div>
        </div>
      )}
    </div>
  );
};

export default Focus;
