import React, { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Box, Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SessionContext } from "../../Session";

const CLOSING_SOON_TIME = 600000;
const TIMER_INTERVAL = 1000;

const MenuFooter = (props) => {
  const { session } = useContext(SessionContext);

  const [time, setTime] = useState("");
  const [closing, setClosing] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      const ms = props.time - Date.now();
      const time = new Date(ms).toISOString().slice(14, 19);

      ms <= CLOSING_SOON_TIME ? setClosing(true) : setClosing(false);
      if (ms <= 0) navigate("/");

      setTime(time);
    }, TIMER_INTERVAL);

    return () => {
      clearInterval(timer);
    };
  }, [props.time, navigate]);

  useEffect(() => {
    if (closing) enqueueSnackbar("10 minutes remaining.", { variant: "warning" });
  }, [closing, enqueueSnackbar]);

  return (
    <div className="drawer-footer">
      <div className="footer-section">
        {session.owner && (
          <div>
            {props.locked && (
              <Tooltip title="Unlock" arrow>
                <Button className="lock-button" disableRipple onClick={props.handleUnlock}>
                  <LockIcon className="lock-icon" />
                </Button>
              </Tooltip>
            )}
            {!props.locked && (
              <Tooltip title="Lock" arrow>
                <Button className="lock-button" disableRipple onClick={props.handleLock}>
                  <LockOpenIcon className="lock-icon" />
                </Button>
              </Tooltip>
            )}
          </div>
        )}
        {!session.owner && (
          <div>
            {props.locked && (
              <Button className="lock-button" disableRipple onClick={props.handleUnlock} disabled>
                <LockIcon className="lock-icon" />
              </Button>
            )}
            {!props.locked && (
              <Button className="lock-button" disableRipple onClick={props.handleLock} disabled>
                <LockOpenIcon className="lock-icon" />
              </Button>
            )}
          </div>
        )}
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <div className="footer-section">
        <PersonIcon className="online-icon" />
        <p>{`${props.connections}`}</p>
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <div className="footer-section">
        {!closing && <p>{time}</p>}
        {closing && <p className="closing-timer">{time}</p>}
      </div>
    </div>
  );
};

export default MenuFooter;
