/**
 * Fetches device location.
 * @returns Geolocation Position Promise
 */
export const getPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

/**
 * Converts position to coordinates.
 * @param {*} pos Geolocation Position
 * @returns [lat, lng]
 */
export const posToCoords = (pos) => {
  return [pos.coords.latitude, pos.coords.longitude];
};
