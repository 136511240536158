import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Button, ClickAwayListener, Tooltip } from "@mui/material";
import React, { useState } from "react";

const MenuHeader = (props) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.sessionId);
    setCopied(true);
  };

  const handleCopiedClose = () => {
    setCopied(false);
  };

  return (
    <div className="drawer-header">
      <div className="close-div">
        {props.alt && (
          <Tooltip title="Close" arrow>
            <Button
              className="close-button"
              disableRipple
              onClick={props.handleClose}
            >
              <KeyboardArrowDownIcon className="close-icon" />
            </Button>
          </Tooltip>
        )}
        {!props.alt && (
          <Tooltip title="Close" arrow>
            <Button
              className="close-button"
              disableRipple
              onClick={props.handleClose}
            >
              <KeyboardArrowRightIcon className="close-icon" />
            </Button>
          </Tooltip>
        )}
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <ClickAwayListener onClickAway={handleCopiedClose}>
        <div className="link-div">
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            title="Copied to Clipboard."
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={copied}
            onClose={handleCopiedClose}
          >
            <Button
              className="link-button"
              variant="outlined"
              endIcon={<ContentCopyIcon />}
              onClick={handleCopy}
            >
              <div className="link-text">{`${props.sessionId}`}</div>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
      <Box sx={{ flexGrow: 1 }} />
      <div className="disconnect-div">
        <Tooltip title="Disconnect" arrow>
          <Button
            className="disconnect-button"
            color="error"
            onClick={props.handleDisconnect}
          >
            <LogoutIcon />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default MenuHeader;
