import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NavigationRoundedIcon from "@mui/icons-material/NavigationRounded";
import PlaceIcon from "@mui/icons-material/Place";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import ResizeableContent from "./ResizableContent";

import { Search } from "../../components";
import { SessionContext } from "../../Session";

const DesktopAppBar = (props) => {
  const { client, route, user } = useContext(SessionContext);

  return (
    <ResizeableContent className="appbar" sx={{ background: user.color }} open={client.drawer}>
      <div className="search-group">
        <div className="search-entry">
          <RadioButtonCheckedIcon className="appbar-icon" />
          <Search
            className="search"
            id="start"
            disabled={route.started || user.route != null}
            placeholder="Choose Start Location"
            value={route.start}
            setValue={props.setStart}
          />
        </div>
        <MoreHorizIcon className="appbar-seperator-icon" />
        <div className="search-entry">
          <PlaceIcon className="appbar-icon" />
          <Search
            className="search"
            id="end"
            disabled={route.started || user.route != null}
            placeholder="Choose Destination"
            value={route.end}
            setValue={props.setEnd}
          />
        </div>
      </div>
      <div className="button-group">
        <Box sx={{ flexGrow: 1 }} />
        {user.route == null && !route.started && (
          <Button
            sx={{ width: "40%" }}
            variant="outlined"
            startIcon={<NavigationRoundedIcon />}
            onClick={props.handleStart}
          >
            Start
          </Button>
        )}
        {user.route != null && !route.canceled && (
          <Button sx={{ width: "40%" }} variant="outlined" startIcon={<CloseIcon />} onClick={props.handleCancel}>
            Cancel
          </Button>
        )}
        {((user.route == null && route.started) || (user.route != null && route.canceled)) && (
          <Button sx={{ width: "40%" }} disabled>
            <CircularProgress sx={{ color: "white" }} size={20} />
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {!client.drawer && (
          <Tooltip title="Menu" arrow>
            <Button onClick={props.handleMenuClicked}>
              <MenuIcon className="appbar-icon" />
            </Button>
          </Tooltip>
        )}
        {!client.drawer && <Box sx={{ flexGrow: 1 }} />}
      </div>
    </ResizeableContent>
  );
};

export default DesktopAppBar;
