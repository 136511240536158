// Initial route state.
// Holds the route start and destination.
export const initial = {
  start: null,
  end: null,
  started: false,
  canceled: false,
};

/**
 * Updates route state, returning new state.
 * @param {*} state Current route state.
 * @param {*} action Non-exhaustive list of updates to route state, former values will be retained if not specified.
 * @example { start: null, ... }
 */
export const reducer = (state, action) => {
  return { ...state, ...action };
};
