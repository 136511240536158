import React from "react";
import "./gridbackground.css";

const GridBackground = (props) => {
  return (
    <div id="grid-container">
      {props.desktop && (
        <svg id="grid-background">
          <clipPath id="grid-clippath">
            <circle id="grid-circle" cx="50%" cy="50%" r="1%" fill="#ffffff" />
          </clipPath>

          <line
            x1="20%"
            y1="0%"
            x2="20%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="30%"
            y1="0%"
            x2="30%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="40%"
            y1="0%"
            x2="40%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="60%"
            y1="0%"
            x2="60%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="70%"
            y1="0%"
            x2="70%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="80%"
            y1="0%"
            x2="80%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />

          <line
            y1="20%"
            x1="0%"
            y2="20%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="30%"
            x1="0%"
            y2="30%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="40%"
            x1="0%"
            y2="40%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="50%"
            x1="0%"
            y2="50%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="60%"
            x1="0%"
            y2="60%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="70%"
            x1="0%"
            y2="70%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="80%"
            x1="0%"
            y2="80%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
        </svg>
      )}
      {!props.desktop && (
        <svg id="grid-background-alt">
          <clipPath id="grid-clippath">
            <circle id="grid-circle" cx="50%" cy="50%" r="1%" fill="#ffffff" />
          </clipPath>

          <line
            x1="20%"
            y1="0%"
            x2="20%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="30%"
            y1="0%"
            x2="30%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="40%"
            y1="0%"
            x2="40%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="60%"
            y1="0%"
            x2="60%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="70%"
            y1="0%"
            x2="70%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            x1="80%"
            y1="0%"
            x2="80%"
            y2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />

          <line
            y1="20%"
            x1="0%"
            y2="20%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="30%"
            x1="0%"
            y2="30%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="40%"
            x1="0%"
            y2="40%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="50%"
            x1="0%"
            y2="50%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="60%"
            x1="0%"
            y2="60%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="70%"
            x1="0%"
            y2="70%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
          <line
            y1="80%"
            x1="0%"
            y2="80%"
            x2="100%"
            stroke="#808080"
            strokeWidth="2"
            clipPath="url(#grid-clippath)"
          />
        </svg>
      )}
    </div>
  );
};

export default GridBackground;
