import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Autocomplete, debounce, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { searchCoords, searchLocation } from "../../../../api/search";
import { SessionContext } from "../../Session";

import "./search.css";

/**
 * Autocomplete search component for addresses.
 * @param {Object} props value, setValue, placeholder, disabled
 * @returns Autocomplete component
 */
const Search = ({ value, setValue, placeholder, disabled }) => {
  const { map } = useContext(SessionContext);
  const { enqueueSnackbar } = useSnackbar();

  const [inputValue, setInputValue] = useState(""); // Input string
  const [options, setOptions] = useState([]); // List of option objects

  /**
   * Fetch autocomplete results from search term.
   */
  const fetchSearch = useMemo(
    () =>
      debounce(async (request, callback) => {
        try {
          callback(await searchLocation(request.input));
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        }
      }, 400),
    [enqueueSnackbar]
  );

  /**
   * Fetch autocomplete results from coordinates.
   */
  const fetchCoords = useMemo(
    () =>
      debounce(async (request, callback) => {
        try {
          callback(await searchCoords(request.input));
        } catch (error) {
          enqueueSnackbar(error.message, { variant: "error" });
        }
      }, 400),
    [enqueueSnackbar]
  );

  // Handles input change (including marker location change).
  useEffect(() => {
    let active = true;

    if (inputValue === "") return undefined;

    // Handles user location selected.
    if (value && value.search === false) return undefined;

    // Handles moved marker.
    if (value && value.search === true) {
      fetchCoords({ input: inputValue }, (results) => {
        if (active && results.length) {
          setValue({
            ...results[0],
            y: value.y,
            x: value.x,
            search: false,
          });
          setOptions(results.slice(1));
        } else {
          setValue({ ...value, search: false });
        }
      });
      return undefined;
    }

    // Default search.
    fetchSearch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];
        setOptions([...new Set(newOptions)]);
      }
    });

    return () => {
      active = false;
    };
  }, [value, setValue, inputValue, fetchSearch, fetchCoords]);

  // Handles moved user position.
  useEffect(() => {
    if (inputValue === "") {
      setOptions(
        value
          ? []
          : [
              {
                key: "current",
                y: map.position[0],
                x: map.position[1],
                label: "Your Location",
                search: false,
              },
            ]
      );
      return undefined;
    }
  }, [map.position, value, inputValue]);

  return (
    <Autocomplete
      id="search-autocomplete"
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => {
        if (option.search || value.search) return true;
        else return option.key === value.key;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className="search-textfield"
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <Grid container className="search-option-container">
            <Grid item className="search-option-item">
              <LocationOnIcon className="locationOnIcon" />
            </Grid>
            <Grid item className="search-option-item-text">
              {option.label}
            </Grid>
          </Grid>
        </li>
      )}
    />
  );
};

export default Search;
