import CloseIcon from "@mui/icons-material/Close";
import DirectionsIcon from "@mui/icons-material/Directions";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NavigationRoundedIcon from "@mui/icons-material/NavigationRounded";
import PlaceIcon from "@mui/icons-material/Place";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Button, CircularProgress, Drawer, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";

import { Search } from "../../components";
import { SessionContext } from "../../Session";

const MobileAppBar = (props) => {
  const { route, user } = useContext(SessionContext);

  const [showDirections, setShowDirections] = useState(false);

  const handleOpenDirections = () => {
    setShowDirections(true);
  };

  const handleCloseDirections = () => {
    setShowDirections(false);
  };

  return (
    <div>
      <div className="appbar" style={{ background: user.color }}>
        <div className="appbar-contents-alt">
          <Tooltip title="Directions">
            <Button onClick={handleOpenDirections}>
              <DirectionsIcon className="appbar-icon" />
            </Button>
          </Tooltip>
          <Tooltip title="Menu">
            <Button onClick={props.handleMenuClicked}>
              <MenuIcon className="appbar-icon" />
            </Button>
          </Tooltip>
        </div>
      </div>
      <Drawer anchor="top" open={showDirections} onClose={handleCloseDirections}>
        <div className="appbar-alt" style={{ background: user.color }}>
          <div className="search-entry-alt">
            <RadioButtonCheckedIcon className="appbar-icon" />
            <Search
              className="search"
              id="start"
              disabled={route.started || user.route != null}
              placeholder="Choose Start Location"
              value={route.start}
              setValue={props.setStart}
            />
            <NavigationRoundedIcon className="mobile-directory-spacing" />
          </div>
          <MoreVertIcon className="appbar-seperator-icon-alt" />
          <div className="search-entry-alt">
            <PlaceIcon className="appbar-icon" />
            <Search
              className="search"
              id="end"
              disabled={route.started || user.route != null}
              placeholder="Choose Destination"
              value={route.end}
              setValue={props.setEnd}
            />
            <div className="nav-button-alt">
              {user.route == null && !route.started && (
                <Tooltip title="Start" arrow>
                  <Button variant="outlined" onClick={props.handleStart}>
                    <NavigationRoundedIcon />
                  </Button>
                </Tooltip>
              )}
              {user.route != null && !route.canceled && (
                <Tooltip title="Cancel" arrow>
                  <Button variant="outlined" onClick={props.handleCancel}>
                    <CloseIcon />
                  </Button>
                </Tooltip>
              )}
              {((user.route == null && route.started) || (user.route != null && route.canceled)) && (
                <Button disabled>
                  <CircularProgress sx={{ color: "white" }} size={20} />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileAppBar;
